import React from 'react'
import './background.scss';

const index = () => {
  return (
    <div className='area'>
    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
        </div>
  )
}

export default index